<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ $t("edit_profile") }}
            </div>

            <div class="subtitle-1 font-weight-light">
              {{ $t("update_your_profile") }}
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <ValidationObserver ref="observer" v-slot="{ validate, reset }">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Company name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.company"
                        :label="$t('company')"
                        required
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <v-text-field
                        v-model="user.email"
                        :label="$t('email')"
                        class="purple-input"
                        required
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="First name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.firstname"
                        :label="$t('first_name')"
                        class="purple-input"
                        required
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Last name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.lastname"
                        :label="$t('last_name')"
                        class="purple-input"
                        required
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.address"
                        :label="$t('address')"
                        class="purple-input"
                        required
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" md="4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.city"
                        :label="$t('city')"
                        class="purple-input"
                        required
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" md="4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Country"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.country"
                        :label="$t('country')"
                        class="purple-input"
                        required
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" md="4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Postal Code"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.postal_code"
                        class="purple-input"
                        :label="$t('postal_code')"
                        type="number"
                        required
                        :error-messages="errors"
                        min="0"
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      color="primary"
                      class="mr-0"
                      @click="update_profile()"
                      >{{ $t("update_profile") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="12">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ $t("personalize") }}
            </div>
            <div class="subtitle-1 font-weight-light">
              {{ $t("personalize_your_menu") }}
            </div>
          </template>
          <v-form>
            <v-container class="py-0">
              <v-col cols="12" md="12">
                <v-file-input
                  :rules="rules"
                  accept="image/png, image/jpeg, image/bmp"
                  :placeholder="$t('upload_logo')"
                  prepend-icon="mdi-camera"
                  label="Logo"
                  v-model="file"
                ></v-file-input>
              </v-col>
              <v-col cols="12" md="6">
                <div
                  style="padding-left: 10px"
                  class="subtitle-1 font-weight-light"
                >
                  {{ $t("menu_color") }}
                </div>
                <v-color-picker
                  v-model="color"
                  class="ma-2"
                  hide-mode-switch
                ></v-color-picker>
              </v-col>
              <v-col cols="12" style="padding-left: 25px">
                <div class="subtitle-1 font-weight-light">
                  {{ $t("menu_languages") }}
                </div>
                <v-checkbox
                  v-for="lang in languages"
                  v-bind:key="lang.value"
                  v-model="lang_model[lang.value]"
                  :label="lang.text"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn @click="save_settings" color="primary" class="mr-0">{{
                  $t("save")
                }}</v-btn>
              </v-col>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as firebase from "firebase";
import mime from "mime-types";
export default {
  data() {
    return {
      languages: [
        { text: "English", value: "en" },
        { text: "Slovenski", value: "sl" },
        { text: "German", value: "ge" },
      ],
      logoExist: false,
      color: null,
      file: null,
      user: {
        id: 0,
        company: "",
        email: "",
        firstname: "",
        lastname: "",
        address: "",
        city: "",
        country: "",
        postal_code: "",
        link: "",
      },
      color: "#E91E63",
      rules: [
        (value) =>
          !value ||
          value.size < 9000000 ||
          "Avatar size should be less than 9 MB!",
      ],
      lang_model: {
        en: false,
        sl: false,
        ge: false,
      },
      meni: {},
    };
  },
  async mounted() {
    //this.user = this.$store.state.authUser;
    let snapshot = await this.$store.dispatch("GET_USER", {
      id: this.$store.state.authUser.id,
    });
    this.user = snapshot.val();
    if (this.user.color) this.color = this.user.color;

    var storageRef = firebase.storage().ref();
    storageRef
      .child("logo/" + this.user.id)
      .getDownloadURL()
      .then(
        (url) => {
          var xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            var blob = xhr.response;
            var fileExtension = mime.extension(blob.type);
            this.file = new File([blob], "logo." + fileExtension, {
              type: blob.type,
            });
          };
          xhr.open("GET", url);
          xhr.send();
        },
        function () {}
      );

    this.$store.dispatch("GET_MENI", { id: this.user.id }).then((snapshot) => {
      this.meni = JSON.parse(JSON.stringify(snapshot.val()));

      if (this.meni && this.meni.languages) {
        this.meni.languages.forEach((lang) => {
          this.lang_model[lang] = true;
        });
      }
    });
  },
  methods: {
    update_profile: function () {
      this.$refs.observer.validate().then((result) => {
        if (result) {
          this.$store.dispatch("SAVE_USER", { user: this.user }).then(() => {
            this.$store.commit("SET_USER", this.user);
            this.$notify({
              group: "foo",
              title: this.$t("success"),
              text: this.$t("profile_updated"),
              type: "success",
            });
          });
        }
      });
    },
    save_settings: function () {
      var storageRef = firebase.storage().ref();
      var logoRef = storageRef.child("logo/" + this.user.id);
      //Update logo
      if (this.file) {
        logoRef.put(this.file).then(function (snapshot) {
          console.log("Uploaded a blob or file!");
        });
      } else {
        // Delete the file
        logoRef
          .delete()
          .then(function () {
            // File deleted successfully
          })
          .catch(function (error) {
            // Uh-oh, an error occurred!
          });
      }
      //Update color
      let userTmp = this.$store.state.authUser;
      userTmp.link = this.user.link;
      userTmp.color = this.color;
      this.$store.dispatch("SAVE_USER", { user: userTmp }).then(() => {
        this.$store.commit("SET_USER", userTmp);
      });
      //update languages
      if (this.meni) this.meni.languages = [];
      else this.meni = { languages: [] };
      Object.keys(this.lang_model).forEach((lang) => {
        if (this.lang_model[lang]) this.meni.languages.push(lang);
      });
      if (this.meni.languages.length == 0) {
        this.meni.languages.push("sl");
        this.lang_model["sl"] = true;
      }
      this.$store.dispatch("SAVE_MENI", {
        id: this.user.id,
        meni: this.meni,
      });

      this.$notify({
        group: "foo",
        title: this.$t("success"),
        text: this.$t("personalization_updated"),
        type: "success",
      });
    },
  },
};
</script>
